.kiosk-components-scope .reg-card-reservation-subsection {
flex:1 1;margin:var(--spacing-sm) 0;margin-inline-end:var(--spacing-xlg)
}

.kiosk-components-scope .reg-card-reservation-subsection:last-of-type {
margin-inline-end:0
}

.kiosk-components-scope .reg-card-reservation-subsection .text-component {
margin-top:var(--spacing-sm)
}

.kiosk-components-scope .reg-card-reservation-subsection .no-wrap {
white-space:nowrap
}

.kiosk-components-scope .reg-card-reservation-subsection-normal-font {
font-weight:var(--font-weight-regular)
}

.kiosk-components-scope .reg-card-reservation-subsection-light-font {
font-weight:var(--font-weight-light)
}

.kiosk-components-scope .reg-card-reservation-subsection .reg-card-subsection-item {
word-break:break-word
}

