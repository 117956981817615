.kiosk-components-scope .address-grid-wrapper .address-row-subgrid {
display:grid;grid-template-rows:subgrid;grid-template-columns:subgrid
}

.kiosk-components-scope .address-grid-wrapper .address-row-subgrid:empty {
display:none
}

.kiosk-components-scope .address-grid-wrapper .address-row-subgrid .address-details {
grid-column:span 8
}

.kiosk-components-scope .address-grid-wrapper .address-row-subgrid .address-details:only-of-type {
grid-column:span 12
}

