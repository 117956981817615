.kiosk-components-scope .reg-card-personal-section {
word-break:break-word
}

.kiosk-components-scope .reg-card-personal-section .reg-card-personal-section-wrapper {
display:grid;grid-template-columns:repeat(2, 1fr);grid-gap:1em;gap:1em
}

@media(min-width: 1024px) {
.kiosk-components-scope .reg-card-personal-section .reg-card-personal-section-wrapper {
grid-template-columns:repeat(3, 1fr)
}


}

.kiosk-components-scope .reg-card-personal-section .reg-card-personal-edit-button {
margin:var(--spacing-xlg) 0 0 auto
}

