.kiosk-components-scope .external-link-header,.kiosk-components-scope .external-link-footer {
z-index:1
}

.kiosk-components-scope .external-link-body {
position:relative;overflow:hidden
}

.kiosk-components-scope .external-link-body .external-link-loader-wrapper {
position:absolute;top:0;left:0;display:flex;align-items:center;justify-content:center;width:100%;height:100%;background-color:var(--body-background-color)
}

.kiosk-components-scope .external-link-body .external-link-iframe {
width:100%;height:100%;border:0
}

