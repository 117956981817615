.kiosk-components-scope .reservation-details-wrapper {
display:flex;align-items:flex-end
}

.kiosk-components-scope .reservation-details-wrapper .reservation-details-section {
flex:1 1
}

.kiosk-components-scope .reservation-details-wrapper .reservation-details-content {
display:flex;flex-direction:column;justify-content:space-between
}

@media(min-width: 1024px) {
.kiosk-components-scope .reservation-details-wrapper .reservation-details-content {
flex-direction:row
}


}

