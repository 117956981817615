.kiosk-components-scope .terms-and-condition-list-wrapper .terms-and-condition-list {
padding-left:0;margin:0
}

.kiosk-components-scope .terms-and-condition-list-wrapper .terms-and-condition-icon {
width:var(--spacing-md);margin-inline-end:var(--spacing-md);fill:var(--body-font-color)
}

.kiosk-components-scope .terms-and-condition-list-wrapper .terms-and-condition-item {
display:flex;align-items:center;margin-top:var(--spacing-xs);list-style:none
}

