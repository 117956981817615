.kiosk-components-scope .registration-card-edit-complementary-wrapper .registration-card-edit-complementary-fields-container {
display:flex;flex-wrap:wrap;gap:var(--spacing-xs) var(--spacing-lg)
}

.kiosk-components-scope .registration-card-edit-complementary-wrapper .registration-card-edit-complementary-field {
flex:1 1;min-width:calc(50% - var(--spacing-lg))
}

@media(min-width: 1024px) {
.kiosk-components-scope .registration-card-edit-complementary-wrapper .registration-card-edit-complementary-field {
min-width:calc(33% - 2*var(--spacing-lg))
}


}

.kiosk-components-scope .registration-card-edit-complementary-wrapper .registration-card-edit-complementary-field.checkbox-button-wrapper {
position:relative;top:-2px;left:-10px;margin-top:var(--spacing-xlg)
}

.kiosk-components-scope .registration-card-edit-complementary-wrapper .registration-card-edit-complementary-field false {
margin-top:0
}

@media(min-width: 1024px) {
.kiosk-components-scope .registration-card-edit-complementary-wrapper .registration-card-edit-complementary-field false {
margin-top:0
}


}

