.kiosk-components-scope .reg-card-summary-form-section {
display:flex
}

.kiosk-components-scope .reg-card-summary-form-section .reg-card-summary-form-section-item-large {
flex:5 1;margin-top:var(--spacing-xlg)
}

@media(min-width: 1024px) {
.kiosk-components-scope .reg-card-summary-form-section .reg-card-summary-form-section-item-large {
flex:1 1
}


}

.kiosk-components-scope .reg-card-summary-form-section .reg-card-summary-form-section-item-small {
flex:3 1;margin-top:var(--spacing-xlg)
}

@media(min-width: 1024px) {
.kiosk-components-scope .reg-card-summary-form-section .reg-card-summary-form-section-item-small {
flex:1 1
}


}

.kiosk-components-scope .reg-card-disclaimer {
margin:var(--spacing-xxlg) 0;text-align:justify
}

