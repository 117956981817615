.kiosk-components-scope .env-settings-modal-device-selection {
display:flex;align-items:center
}

.kiosk-components-scope .env-settings-modal-device-selection:first-of-type {
margin-top:var(--spacing-lg)
}

.kiosk-components-scope .env-settings-modal-device-selection .env-settings-modal-device-field {
flex:1 1
}

