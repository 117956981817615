.kiosk-components-scope .single-phone-component .phone-grid-wrapper {
grid-template-columns:repeat(12, 1fr)
}

.kiosk-components-scope .single-phone-component .phone-grid-wrapper .phone-type {
grid-column:span 4
}

.kiosk-components-scope .single-phone-component .phone-grid-wrapper .phone-prefix {
grid-column:span 3
}

.kiosk-components-scope .single-phone-component .phone-grid-wrapper .phone-prefix+.phone-details {
grid-column:span 5
}

.kiosk-components-scope .single-phone-component .phone-grid-wrapper .phone-prefix:first-of-type+.phone-details {
grid-column:span 9
}

.kiosk-components-scope .single-phone-component .phone-grid-wrapper .phone-details {
grid-column:span 8
}

.kiosk-components-scope .single-phone-component .phone-grid-wrapper .phone-details:only-of-type {
grid-column:span 12
}

