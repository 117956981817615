.kiosk-components-scope .kiosk-components-scope {
--test-enviroment-bar-height:40px;--test-enviroment-bar-color:#c30f98
}

.kiosk-components-scope .kiosk-components-scope,.kiosk-components-scope #root {
width:100%;height:100dvh;max-height:-webkit-fill-available;max-height:-moz-available;max-height:stretch;padding:0;margin:0;overflow:hidden;overscroll-behavior:none;font-family:var(--main-font);color:var(--body-font-color);background:var(--body-background-color)
}

.kiosk-components-scope, .kiosk-components-scope * {
-webkit-user-select:none;-o-user-select:none;user-select:none;-webkit-tap-highlight-color:rgba(0,0,0,0)
}

.kiosk-components-scope input {
-webkit-user-select:text;-o-user-select:text;user-select:text
}

.kiosk-components-scope, .kiosk-components-scope *,.kiosk-components-scope::before, .kiosk-components-scope *::before,.kiosk-components-scope::after, .kiosk-components-scope *::after {
box-sizing:border-box
}

