.kiosk-components-scope .single-mobile-component .mobile-grid-wrapper {
grid-template-columns:repeat(12, 1fr)
}

.kiosk-components-scope .single-mobile-component .mobile-grid-wrapper .mobile-type {
grid-column:span 4
}

.kiosk-components-scope .single-mobile-component .mobile-grid-wrapper .mobile-prefix {
grid-column:span 3
}

.kiosk-components-scope .single-mobile-component .mobile-grid-wrapper .mobile-prefix+.mobile-details {
grid-column:span 5
}

.kiosk-components-scope .single-mobile-component .mobile-grid-wrapper .mobile-prefix:first-of-type+.mobile-details {
grid-column:span 9
}

.kiosk-components-scope .single-mobile-component .mobile-grid-wrapper .mobile-details {
grid-column:span 8
}

.kiosk-components-scope .single-mobile-component .mobile-grid-wrapper .mobile-details:only-of-type {
grid-column:span 12
}

