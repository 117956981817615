.kiosk-components-scope .consent-page-wrapper {
margin-top:var(--spacing-xlg)
}

.kiosk-components-scope .consent-page-wrapper .section-component {
border-bottom:none
}

.kiosk-components-scope .consent-page-wrapper .consent-page-title {
margin-bottom:var(--spacing-lg)
}

.kiosk-components-scope .consent-page-wrapper .consent-page-body {
white-space:pre-wrap
}

